import React from 'react'
import {HStack,Text,Box,Heading,SimpleGrid,AspectRatio} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import Link from '../components/gatsbylink'

import {motion} from 'framer-motion'
import MySeo from '../components/seo'

 function Beitrag1 (){
    
    return(
      <motion.div
  initial={{opacity:0,scale:.3,paddingBottom:"100px"}}
  animate={{opacity:1,scale:1}}
  transition={{duration:.3,delay:.1,ease:'easeOut'}}
  >
    <MySeo title="Historischer Abriss des Landjudentums am Mittelrhein" description='Historischer Abriss. Dokumentation' />
        
        <HStack mt={["30px","65px","65px"]} mb="15"  justifyContent="space-between">

<Box>
<StaticImage
  src="../images/imgbeitrag1/2.jpeg"
  alt="Werner Abbildung"
  layout='constrained'
  height={350}
  width={400}
  />
</Box>
<Box>
<StaticImage
  src="../images/solo/viehhandel.jpeg"
  alt="Jüdische Integration"
  layout='constrained'
  height={350}
  width={400}
/>

</Box>
<Box>
<StaticImage
  src="../images/beitrag/1-FamMayer.jpg"
  alt="Jüdische Integration"
 
  layout='constrained'
  height={350}
  width={400}
/>
</Box>
</HStack>

     <Heading  variant="Titel">Jüdisches Leben im Hunsrück</Heading>
  
     <SimpleGrid  columns={[1,1,2]} gap="6" >
        
          <Box>
          <Text variant='solid' >Seit 1700 Jahren leben Jüdinnen und Juden nachweislich auf dem Gebiet des heutigen Deutschland.  Mit den römischen Legionen waren auch Menschen jüdischer Religion ins Rheinland gekommen, möglicherweise als Kaufleute, Winzer, Weinhändler, Soldaten oder Ärzte. In einem Edikt aus dem Jahr 321 gestattete Kaiser Konstantin die Berufung von Juden in Ämter der Stadtverwaltung Köln. Es handelt sich um den ältesten urkundlichen Beleg für die Existenz einer jüdischen Gemeinde in Deutschland. Ab dem 12. und 13. Jahrhundert sind jüdische Gemeinden auch in vielen linksrheinischen Städten im Oberen Mittelrheintal nachweisbar. Die erste Erwähnung von Juden im Rhein-Hunsrück-Raum datiert in das Jahr 1074, als Juden aus Worms Zollbefreiung an der Zollstätte in Boppard gewährt wurde. 
               </Text>
               <Text variant='solid'>
               Nach den Vertreibungen aus westeuropäischen Ländern und vielen Städten (z.B. aus dem kurtrierischen Oberwesel 1418/19) ließen sich ab dem 16. Jahrhundert jüdische Familien vermehrt in dem ländlichen Gebiet zwischen Nahe, Mosel und Rhein nieder. Die zentrale Lage zwischen vielen mächtigen Landesherrn und die territoriale Zersplitterung des Deutschen Reiches bis 1806 begünstigten die Niederlassung in bestimmten Orten des Hunsrück, während wenige Meter weiter Jüdinnen und Juden unerwünscht waren. Als sog. Schutzjuden finanziell ausgenutzt, erhielten sie gegen hohe Zahlungen eine „Aufenthaltserlaubnis“, ein Schutzbrief gewährte ihnen – oft nur auf dem Papier - ein Minimum an Sicherheit. Um 1800 lebten etwa 90% der deutschen Jüdinnen und Juden in Dörfern und Kleinstädten.
           
           </Text>
         
           <Text variant='solid'>
           Die liberale Gesetzgebung des revolutionären Frankreichs, die nach dessen Eroberung auch im Rheinland Gültigkeit erhielt, eröffnete der jüdischen Bevölkerung neue Freiheiten sowie die erstmalige Anerkennung als Staatsbürger. Dadurch begünstigt, erlebte das Landjudentum im 19. Jahrhundert einen nie dagewesenen Aufschwung. In immer mehr Hunsrückorten gründeten sich Gemeinden, die oft jedoch so klein waren, dass es gerade für den Minjan, die vorgeschriebene Anzahl von 10 Männern für die Abhaltung eines Gottesdienstes, reichte. Einen ausgebildeten Rabbiner konnten sich die kleinen Landgemeinden in der Regel jedoch nicht leisten. Die religiöse Praxis blieb so über Generationen hinweg einigermaßen unverändert konserviert und zeichnete sich durch eine ausgesprochene Frömmigkeit aus.
           </Text>
           <Text variant="solid">
           Mit der rechtlichen Emanzipation der Juden ging ihre Annäherung an die christliche Mehrheitsgesellschaft einher. Dennoch blieben ihnen viele Berufe unzugänglich. Lange Zeit vom Landerwerb ausgeschlossen, verdingte sich die überwiegende Mehrzahl als Händler, insbesondere Viehhändler. Handwerksberufe beschränkten sich zumeist auf die für die Ausübung religiöser Riten notwendigen Berufe wie Metzger (Schächter), Bäcker, Färber oder Textilbearbeiter. Bis ins 19. Jahrhundert gab es daneben umherziehende Kleinhändler, die mit Kleinwaren auf ihrem Rücken von Dorf zu Dorf zogen. So bildeten jüdische Geschäftsleute die Verbindung zwischen Stadt und Land und pflegten gute Kontakte über Dorf- und sogar Staatsgrenzen hinweg.
           </Text>
           <Box>
           <StaticImage
  src="../images/beitrag/1-FamMayer.jpg"
  alt="Jüdische Integration"
 
  layout='constrained'
  
/>
<Text variant="Bild">Familie Mayer</Text>
           </Box>
           <Box>
           <StaticImage
  src="../images/beitrag/2-Handschlag.jpg"
  alt="Jüdische Integration"
 
  layout='constrained'
  
/>
<Text variant="Bild">Der Viehhändler Julius Hirsch auf dem Marktplatz in Kastellaun: Das Geschäft wird mit Handschlag besiegelt.</Text>
           </Box>
           <Text variant='solid'>Die Krisen der Weimarer Republik trafen die jüdischen Familien ebenso wie ihre christlichen Nachbarn, sodass viele in die Großstädte zogen oder ins Ausland auswanderten. Ihre Kinder zogen nun andere Berufe vor, gingen auf höhere Schulen, verließen das Dorf. Ein Wandel des Landjudentums begann, der durch die Rassenpolitik der Nationalsozialisten gewaltsam beendet wurde. Die Jahrhunderte alte Tradition des Landjudentums fand in der geplanten Vernichtung der europäischen Juden ihr schreckliches Ende.</Text>
           <Text variant='solid'>Die im öffentlichen Raum präsentierte Wanderausstellung „Jüdisches Leben auf dem Hunsrück“, die in Bacharach am Aufgang zur Wernerkapelle zu sehen ist, entstand in Kooperation zwischen dem Förderkreis Synagoge Laufersweiler und der Initiative für Freizeit und Musikkultur für das „Auf Anfang!“- Festival 2021 in Auen (Landkreis Bad Kreuznach).</Text>
           <Box>
           <StaticImage
  src="../images/beitrag/11-HenryJoseph.jpg"
  alt="Henry Joseph im KZ"
 
  layout='constrained'
  
/>
<Text variant="Bild">Am 15. April 1945 wurde der 19-jährige Heinz Joseph aus Laufersweiler von englischen Truppen aus dem KZ Bergen-Belsen befreit. </Text>
           </Box>
           <Box>
           <StaticImage
  src="../images/beitrag/12-Wurzelreisen.jpg"
  alt="Rolf Shimon Mayer 1998 mit seinem Enkelsohn Uri"
 
  layout='constrained'
  
/>
<Text variant="Bild">Rolf Shimon Mayer 1998 mit seinem Enkelsohn Uri auf dem jüdischen Friedhof in Laufersweiler. </Text>
           </Box>
          

           <Text mb="5">Text von Carolin Manns</Text>
          </Box>
              
               
           <Box>
           
           
           <Text variant='solid'><span style={{fontWeight:600}}>Christof Pies und Carolin Manns vom Förderkreis Synagoge Laufersweiler e.V. </span> über die Ausstellung <span style={{fontWeight:600}}> „Erwachet aus dem langen Schlafe: Jüdisches Leben auf dem Lande – Juden im Hunsrück“ im Hunsrück-Museum Simmern</span></Text>
         
           <AspectRatio pt="4"pb="7" maxW='460px' ratio={1.9}>
      <iframe  width="auto%" margin="2px" 
        title="Eine Film-Skizze zum jüdischen Leben im Hunsrück"
        src="https://player.vimeo.com/video/702009140?h=d5eea5252b"
        allowFullScreen
      />
  </AspectRatio>
    
  <Link href=" https://anchor.fm/initiativefm/episodes/IFM-Podcast--Jdisches-Leben-im-Hunsrck--mit-Kuratorin-Carolin-Manns--2021JLiD-e1e8asf/a-a7dilu7">
         <Text _hover={{color:'teal'}} py="3" fontWeight="600"> 
        &rarr; Podcast zur Ausstellung 
        </Text>
         </Link>
         <Link href="https://www.synagoge-laufersweiler.de/de/ausstellung-erwachet-aus-dem-langen-schlafe">
         <Text _hover={{color:'teal'}} py="3" variant='solid'  fontWeight="600">
         &rarr; weitere Texte und Material zu Ausstellung 
         </Text>
         </Link>

         <Box>
           <StaticImage
  src="../images/beitrag/3-Matzenbaeckerei.jpg"
  alt="Jüdische Integration"
 
  layout='constrained'
  
/>
<Text variant="Bild">In der Matzenbäckerei Joseph in Laufersweiler waren sowohl jüdische als auch christliche Angestellte beschäftigt.</Text>
           </Box>
           <Box>
           <StaticImage
  src="../images/beitrag/6-Schule.jpg"
  alt="Jüdische Integration"
 
  layout='constrained'
  
/>
<Text variant="Bild">Die evangelische Volksschule Bacharach um 1937 – nur wenig später wurden jüdische Schüler vom gemeinsamen Unterricht ausgeschlossen.</Text>
           </Box>
           <Box>
           <StaticImage
  src="../images/beitrag/7-SchützenvereinWT.jpg"
  alt="Jüdische Integration"
 
  layout='constrained'
  
/>
<Text variant="Bild">Fest verankert im Vereinswesen: Der Schützenverein "Wilhelm Tell" 1929 mit dem zweimaligen Schützenkönig Isaak Forst</Text>
           </Box>
           <Box>
           <StaticImage
  src="../images/beitrag/8-ErsterWeltkrieg.jpg"
  alt="Jüdische Integration"
 
  layout='constrained'
  
/>
<Text variant="Bild"> Sally Mayer (3. von links) aus Laufersweiler war Sanitätsgefreiter im Ersten Weltkrieg.</Text>
           </Box>
           <Box>
           <StaticImage
  src="../images/beitrag/10-Zerstoerte_Synagoge_Kastellaun.jpg"
  alt="Jüdische Integration"
 
  layout='constrained'
  
/>
<Text variant="Bild"> Die zerstörte Synagoge Kastellauns nach dem Novemberpogrom 1938</Text>
           </Box>
        
           <Box>
           <StaticImage
  src="../images/beitrag/9-Friedhof.jpg"
  alt="Jüdischer Friedhof in Sohren"
 
  layout='constrained'
  
/>
<Text variant="Bild">Jüdischer Friedhof in Sohren</Text>
           </Box>

           </Box>
       
           
      
     </SimpleGrid>
 

    
</motion.div>

    )}
  



export default Beitrag1